/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

var html = [], errors = [], userVals = {};
var PrezenttCalc = {
	
	useDefaults : true,
	
	userDefaults : {
		numSessions : 10,
		avgAttendees : 50
	},
	
	competitionRates : {
		perSession : 500,
		perAttendee : 100,
		perYearCalc : 0
	},
	
	prezenttRates : {
		perSession : 100,
		perAttendee : 2,
		perYearCalc : 0,
		saving : 0
	},
	
	isNumeric : function(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	},
	
	thousandFormat : function (x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},
	
	init : function(){
		$(document).on('submit', 'form.prezentt-calculator', function(e){
			html = []; 
			errors = [];
			
			userVals = {
				numSessions : !PrezenttCalc.useDefaults || $('#numSessions').val() !== '' ? parseInt($('#numSessions').val()) : PrezenttCalc.userDefaults.numSessions,
				avgAttendees : !PrezenttCalc.useDefaults || $('#avgAttendees').val() !== '' ? parseInt($('#avgAttendees').val()) : PrezenttCalc.userDefaults.avgAttendees ,
			};
			
			$('#input_8_10').val(userVals.numSessions);
			$('#input_8_11').val(userVals.avgAttendees);
			
			//check valid inputs
			if(!PrezenttCalc.isNumeric(userVals.numSessions) || userVals.numSessions <= 0){
				errors.push('Please provide the number of training sessions per year');
			}
			if(!PrezenttCalc.isNumeric(userVals.avgAttendees) || userVals.avgAttendees <= 0){
				errors.push('Please provide the average number of attendees per session');
			}
			
			if(!errors.length){
				html.push('<ul class="list-group">');
				
				//calc rates competition/prezentt
				PrezenttCalc.competitionRates.perYearCalc = (userVals.numSessions * PrezenttCalc.competitionRates.perSession) + (userVals.avgAttendees * PrezenttCalc.competitionRates.perAttendee);
				PrezenttCalc.prezenttRates.perYearCalc = (userVals.numSessions * PrezenttCalc.prezenttRates.perSession) + (userVals.avgAttendees * PrezenttCalc.prezenttRates.perAttendee);
				PrezenttCalc.prezenttRates.saving = PrezenttCalc.competitionRates.perYearCalc - PrezenttCalc.prezenttRates.perYearCalc;
				
				html.push('<li class="list-group-item"><p>You currently spend ~<strong>$' + PrezenttCalc.thousandFormat(PrezenttCalc.competitionRates.perYearCalc) + '</strong> per year delivering training.</p></li>');
				html.push('<li class="list-group-item point-list-container"><p>We can save you <strong>$' + PrezenttCalc.thousandFormat(PrezenttCalc.prezenttRates.saving) + '</strong> per year delivering training PLUS deliver the following features:</p>');
				html.push('<ul>');
				html.push('<li>Far less cost</li>');
				html.push('<li>Increase sales &amp; engagement</li>');
				html.push('<li>Cut administration time drastically</li>');
				html.push('<li>Go green</li>');
				html.push('</ul>');
				html.push('<a href="#" id="btn-calculator-demo" class="btn btn-pink btn-lg btn-block pps-btn pps-button-popup-1060">Request Demo</a>');
				html.push('</li></ul>');

			}else{
				html.push('<div class="alert alert-danger"><h4>Errors in your submission</h4><p>' + errors.join("<br />") + '</p></div>');
			}
			$('#calculator-results').html(html.join("\n"));
			return false;
		});
		
		$('#numSessions').attr('placeholder', PrezenttCalc.userDefaults.numSessions);
		$('#avgAttendees').attr('placeholder', PrezenttCalc.userDefaults.avgAttendees);
	}
};

(function($) {
	PrezenttCalc.init();
})(jQuery);